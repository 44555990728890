<template>
  <div class="h5-solution-box">
    <div v-for="item in solutionSortTwoList" :key="item.id" @click="solutionList(item.id,item.pid)">
      <img :src="require(`@/assets/img/${item.id}.png`)" />
      <span>{{item.name}}</span>
    </div>
  </div>
</template>

<script>
import { getCategoryList } from "@/api/solution";
export default {
  data(){
    return{
      solutionSortTwoList:[]
    }
  },
  mounted(){
    this.loadGetSolutionCategory();
  },
  methods: {
    // 类别列表
    loadGetSolutionCategory() {
      getCategoryList('1').then((res) => {
        let data = res;
        data.forEach((element) => {
          element.children.forEach((item) => {
            this.solutionSortTwoList.push(item);
          });
        });
      });
    },
    solutionList(id,pid) {
      this.$router.push({
        path: "/solution/list",
        query: { id,pid },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.h5-solution-box {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 167px;
    height: 94px;
    border-radius: 4px 4px 4px 4px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #333;
      opacity: 0.4;
    }
    > img {
      width: 100%;
      height: 100%;
      display: block;
    }
    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: #fff;
      line-height: 22px;
      z-index: 6;
      white-space: nowrap;
    }
  }
}
</style>